import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Layout.css';
import '../css/Global.css';
import apiService from '../services/api';
import logo from '../assets/logo.png';

const Layout = ({ children }) => {
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const role = await apiService.getUserRole();
                setUserRole(role);
            } catch (error) {
                console.error('Erro ao obter o papel do usuário:', error);
            }
        };

        fetchUserRole();
    }, []);

    const handleLogout = () => {
        apiService.logout();
        navigate('/');
    };

    return (
        <div className="layout">
            <aside className="sidebar">
                <div className="sidebar-header">
                    <img src={logo} alt="Jusgestor Software" className="logo-sidebar" />
                    <h2>Publicações Online</h2>
                </div>
                <nav className="sidebar-nav">
                    <Link to="/dashboard">Início</Link>
                    <Link to="/clientes">Clientes</Link>
                    <Link to="/intimacoes">Intimações</Link>
                    <Link to="/processos">Processos</Link>
                    <Link to="/compromissos">Compromissos</Link>
                    {userRole === 'admin' && <Link to="/admin">Admin</Link>}
                    <button onClick={handleLogout} className="logout-button">Sair</button>
                </nav>
            </aside>
            <main className="main-content">
                <header className="main-header">
                    <h1>Painel de Controle</h1>
                </header>
                <div className="content">
                    {children}
                </div>
            </main>
        </div>
    );
};

export default Layout;
