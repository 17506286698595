import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDashboardTotals } from '../services/api';
import '../css/Dashboard.css';
import { FaBell, FaTasks, FaCalendarAlt, FaFileAlt } from 'react-icons/fa';

const Dashboard = () => {
  const [totals, setTotals] = useState({
    total_notificacoes: 0,
    total_eventos: 0,
    total_compromissos: 0,
  });

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        const data = await getDashboardTotals();
        setTotals(data);
      } catch (error) {
        console.error('Erro ao buscar totais:', error);
      }
    };

    fetchTotals();
  }, []);

  return (
    <div>
      <header className="dashboard-header">
        <p>Resumo de suas atividades</p>
      </header>
      <div className="dashboard-grid">
        <div className="card">
          <div className="card-icon">
            <FaBell size={30} />
          </div>
          <h3>Intimações</h3>
          <p>{totals.total_notificacoes} hoje</p>
          <Link to="/intimacoes">Ver Intimações</Link>
        </div>
        <div className="card">
          <div className="card-icon">
            <FaTasks size={30} />
          </div>
          <h3>Movimentações</h3>
          <p>{totals.total_eventos} hoje</p>
          <Link to="/movimentacoes">Ver Movimentações</Link>
        </div>
        <div className="card">
          <div className="card-icon">
            <FaFileAlt size={30} />
          </div>
          <h3>Processos</h3>
          <p>Cadastre seus processos</p>
          <Link to="/processos">Ver Processos</Link>
        </div>
        <div className="card">
          <div className="card-icon">
            <FaCalendarAlt size={30} />
          </div>
          <h3>Agenda</h3>
          <p>{totals.total_compromissos} compromissos hoje</p>
          <Link to="/compromissos">Ver Agenda</Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
