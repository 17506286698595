import React from 'react';

const Admin = () => {
    return (
        <div className="admin-container">
            <h2>Administração</h2>
            <p>Área exclusiva para administradores</p>
        </div>
    );
};

export default Admin;
