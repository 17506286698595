import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './components/Dashboard';
import ProcessList from './components/ProcessList';
import NotificationList from './components/NotificationList';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import Compromissos from './components/Compromissos';
import Clientes from './components/Clientes';
import Admin from './components/Admin';
import Logout from './components/Logout.js'; // Adicionei o componente de logout
import './css/styles.css';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<PrivateRoute><Layout><Dashboard /></Layout></PrivateRoute>} />
                <Route path="/intimacoes" element={<PrivateRoute><Layout><NotificationList /></Layout></PrivateRoute>} />
                <Route path="/processos" element={<PrivateRoute><Layout><ProcessList /></Layout></PrivateRoute>} />
                <Route path="/compromissos" element={<PrivateRoute><Layout><Compromissos /></Layout></PrivateRoute>} />
                <Route path="/clientes" element={<PrivateRoute><Layout><Clientes /></Layout></PrivateRoute>} /> {/* Nova Rota */}
                <Route path="/admin" element={<PrivateRoute><Layout><Admin /></Layout></PrivateRoute>} />
                <Route path="/logout" element={<Logout />} /> {/* Adicionando a rota de logout */}

            </Routes>
        </Router>
    );
};

export default App;