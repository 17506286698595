import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { getCompromissos } from '../services/api';
import ModalCompromisso from './ModalCompromisso';
import '../css/Compromissos.css';

const Compromissos = () => {
    const [compromissos, setCompromissos] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCompromisso, setSelectedCompromisso] = useState(null);

    const fetchCompromissos = async () => {
        try {
            const data = await getCompromissos();
            setCompromissos(data);
        } catch (error) {
            console.error('Erro ao buscar compromissos:', error);
        }
    };

    useEffect(() => {
        fetchCompromissos();
    }, []);

    const handleAdd = () => {
        setSelectedCompromisso(null);
        setShowModal(true);
    };

    const handleEdit = (compromisso) => {
        setSelectedCompromisso(compromisso);
        setShowModal(true);
    };

    

    const handleClose = () => {
        setShowModal(false);
        fetchCompromissos();
    };

    return (
        <div className="compromissos-container">
            <Button variant="primary" onClick={handleAdd}>
                Adicionar Compromisso
            </Button>
            <ul className="compromissos-list">
                {compromissos && compromissos.length > 0 ? (
                    compromissos.map((compromisso) => (
                        <li key={compromisso.id} className="compromisso-item">
                            <span>{compromisso.titulo}</span>
                            <span>{compromisso.notificacao}</span>
                            <span>{compromisso.tipo_evento}</span>
                            <Button variant="secondary" onClick={() => handleEdit(compromisso)}>
                                Editar
                            </Button>
                        </li>
                    ))
                ) : (
                    <li>Nenhum compromisso encontrado.</li>
                )}
            </ul>
            <ModalCompromisso
                show={showModal}
                handleClose={handleClose}
                compromisso={selectedCompromisso}
                refreshCompromissos={fetchCompromissos}
            />
        </div>
    );
};

export default Compromissos;

