import React, { useEffect, useState } from 'react';
import { getClients } from '../services/api';
import '../css/Clientes.css';

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const data = await getClients(token);
                setClients(data);
            } catch (error) {
                console.error('Erro ao buscar clientes:', error);
            }
        };

        fetchClients();
    }, []);

    const filteredClients = clients.filter(client =>
        client.nome && client.nome.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div className="clients-container">
            <h2>Clientes</h2>
            <input
                type="text"
                placeholder="Filtrar por nome"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="filter-input"
            />
            <ul className="clients-list">
                {filteredClients.map(client => (
                    <li key={client.parte_id}>
                        <h3>{client.nome}</h3>
                        <p>CPF/CNPJ: {client.cpf_cnpj}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Clients;
